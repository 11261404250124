import { FC } from 'react';
import ImbalanceSignal from './components/ImbalanceSignal/ImbalanceSignal';
import BalanceSignal from './components/BalanceSignal/BalanceSignal';
// React Chart Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

interface GTSPanelProps {
  option?: 'edit' | 'create';
  areaId?: string;
}

export const GTSPanel: FC<GTSPanelProps> = () => {
  return (
    <div>
      GTS PANEL
      <ImbalanceSignal />
      <BalanceSignal />
    </div>
  );
};
