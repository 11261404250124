import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';

const ImbalanceSignal = () => {
  const data1 = [
    {
      hour: 1726869600000,
      energy: 100,
    },
    {
      hour: 1726873200000,
      energy: 200,
    },
    {
      hour: 1726876800000,
      energy: 100,
    },
    {
      hour: 1726880400000,
      energy: 200,
    },
    {
      hour: 1726884000000,
      energy: 470,
    },
    {
      hour: 1726887600000,
      energy: 200,
    },
    {
      hour: 1726891200000,
      energy: 200,
    },
    {
      hour: 1726894800000,
      energy: 200,
    },
    {
      hour: 1726898400000,
      energy: 200,
    },
    {
      hour: 1726902000000,
      energy: 200,
    },
    {
      hour: 1726905600000,
      energy: 232,
    },
  ];

  const options: AgChartOptions = {
    title: {
      text: 'Portfolio imbalance signal',
    },
    // subtitle: {
    //   text: 'In Billion U.S. Dollars',
    // },
    data: data1,
    series: [
      {
        type: 'bar',
        xKey: 'hour',
        xName: 'Time (LET)',
        yKey: 'energy',
        yName: 'GSSWISSGAS POS',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        max: 1000,
        interval: { step: 100 },
        title: { text: 'Energy (kWh)' },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time (LET)' },
      },
    ],
    legend: {
      enabled: true,
      position: 'right',
    },
  };

  return <AgCharts options={options} />;
};

export default ImbalanceSignal;
