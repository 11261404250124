import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import 'ag-charts-enterprise';

const BalanceSignal = () => {
  const chartData = [
    {
      timeStamp: new Date('2024-09-24 00:00:00'),
      energy: 100,
      lowerEnergy: -50000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 01:00:00'),
      energy: 200,
      lowerEnergy: -55000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 02:00:00'),
      energy: 100,
      lowerEnergy: -56000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 03:00:00'),
      energy: 200,
      lowerEnergy: -57000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 04:00:00'),
      energy: 470,
      lowerEnergy: -58000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 05:00:00'),
      energy: 200,
      lowerEnergy: -55000,
      upperEnergy: 90003,
    },
    {
      timeStamp: new Date('2024-09-24 06:00:00'),
      energy: 200,
      lowerEnergy: -53000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 07:00:00'),
      energy: 200,
      lowerEnergy: -51000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 08:00:00'),
      energy: 200,
      lowerEnergy: -50000,
      upperEnergy: 89003,
    },
    {
      timeStamp: new Date('2024-09-24 09:00:00'),
      energy: 200,
      lowerEnergy: -50000,
      upperEnergy: 93003,
    },
    {
      timeStamp: new Date('2024-09-24 10:00:00'),
      energy: 232,
      lowerEnergy: -50000,
      upperEnergy: 89003,
    },
  ];

  const chartData2 = [
    {
      timeStamp: new Date('2024-09-24 00:00:00'),
      lowerEnergyLG: -40000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 01:00:00'),
      lowerEnergyLG: -45000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 02:00:00'),
      lowerEnergyLG: -46000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 03:00:00'),
      lowerEnergyLG: -47000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 04:00:00'),
      lowerEnergyLG: -48000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 05:00:00'),
      lowerEnergyLG: -45000,
      upperEnergyLG: 70003,
    },
    {
      timeStamp: new Date('2024-09-24 06:00:00'),
      lowerEnergyLG: -43000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 07:00:00'),
      lowerEnergyLG: -41000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 08:00:00'),
      lowerEnergyLG: -40000,
      upperEnergyLG: 79003,
    },
    {
      timeStamp: new Date('2024-09-24 09:00:00'),
      lowerEnergyLG: -40000,
      upperEnergyLG: 73003,
    },
    {
      timeStamp: new Date('2024-09-24 10:00:00'),
      lowerEnergyLG: -40000,
      upperEnergyLG: 79003,
    },
  ];

  const options: AgChartOptions = {
    title: {
      text: 'Portfolio Balance signal',
    },
    // subtitle: {
    //   text: 'In Billion U.S. Dollars',
    // },
    data: [...chartData, ...chartData2],
    series: [
      {
        type: 'bar',
        xKey: 'timeStamp',
        xName: 'Time (LET)',
        yKey: 'energy',
        yName: 'GSSWISSGAS POS',
      },
      {
        type: 'range-area',
        xKey: 'timeStamp',
        yLowKey: 'lowerEnergy',
        yHighKey: 'upperEnergy',
        stroke: 'orange',
        fill: 'orange',
        yName: 'Low Border orange zone',
      },
      {
        type: 'range-area',
        xKey: 'timeStamp',
        yLowKey: 'lowerEnergyLG',
        yHighKey: 'upperEnergyLG',
        stroke: 'green',
        fill: 'green',
        yName: 'Low Border light green zone',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        max: 100000,
        min: -100000,
        interval: { step: 100 },
        title: { text: 'Energy (kWh)' },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time (LET)' },
      },
    ],
    legend: {
      enabled: true,
      position: 'right',
    },
  };

  return <AgCharts options={options} />;
};

export default BalanceSignal;
