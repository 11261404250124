import { ReactElement } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Header, Navigation } from 'components';
import { ErrorScreen } from 'features/ErrorScreen';
import {
  Nomination,
  MessageHistory,
  MessageDetailsPage,
  MasterdataPage,
  CounterpartyFormPage,
  BalancegroupFormPage,
  MessageFakeResponsePage,
  MessageDefinitionsPage,
  MessageDefinitionFormPage,
  AreasTopologyPage,
  NominationTrackFormPage,
  DashboardPage,
  AreaFormPage,
  ConnectionPointFormPage,
  AdminPage,
} from 'pages';
import { SettingsModal } from 'features/SettingsModal';
import { DeletionErrorModal } from 'components';
import { GTSPage } from 'pages/GTSPage';

const MainScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RouterContainer = styled.div`
  display: flex;
  position: relative;
`;

export const AppRouter = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <MainScreenContainer>
      <Header />
      <RouterContainer>
        <Navigation />
        <Switch>
          <Route exact path={`${path}`}>
            <DashboardPage />
          </Route>
          <Route exact path={`${path}dashboard-new/`}>
            <DashboardPage newOne={true} />
          </Route>
          <Route exact path={`${path}nominations/`}>
            <Nomination />
          </Route>
          <Route exact path={`${path}history/`}>
            <MessageHistory />
          </Route>
          <Route exact path={`${path}ack/`}>
            <MessageFakeResponsePage />
          </Route>
          <Route exact path={`${path}history/message/:id/`}>
            <MessageDetailsPage />
          </Route>
          <Route exact path={`${path}masterdata/`}>
            <MasterdataPage />
          </Route>
          <Route exact path={`${path}masterdata/counterparty/:option/:id?`}>
            <CounterpartyFormPage />
          </Route>
          <Route
            exact
            path={`${path}masterdata/:counterpartyName/:counterpartyId/balancegroup/:option/:id?`}
          >
            <BalancegroupFormPage />
          </Route>
          <Route exact path={`${path}message-definitions/`}>
            <MessageDefinitionsPage />
          </Route>
          <Route
            exact
            path={`${path}message-definitions/nomination-track/:option/:messageDefId/:edigasBaseVersion/:id?`}
          >
            <NominationTrackFormPage />
          </Route>
          <Route exact path={`${path}message-definitions/:option/:id?`}>
            <MessageDefinitionFormPage />
          </Route>
          <Route exact path={`${path}areas/`}>
            <AreasTopologyPage />
          </Route>
          <Route exact path={`${path}gts/`}>
            <GTSPage />
          </Route>
          <Route
            exact
            path={`${path}areas/connection-point/:areaName/:areaId/:option/:areaSortOrder/:id?`}
          >
            <ConnectionPointFormPage />
          </Route>
          <Route exact path={`${path}admin-panel/`}>
            <AdminPage />
          </Route>
          <Route exact path={`${path}areas/:option/:id?`}>
            <AreaFormPage />
          </Route>
          <Route path="*">
            <Redirect to="/404/" />
          </Route>
        </Switch>
        <ErrorScreen />
        <SettingsModal />
        <DeletionErrorModal />
      </RouterContainer>
    </MainScreenContainer>
  );
};
